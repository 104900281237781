import React from "react"
import Layout from "../components/layout"
import TextBlock from "../components/TextBlock/textBlock"
import Button from "../components/Button/button"
import { Link } from "gatsby"

export default () => (
  <>
    <Layout>
      <TextBlock
        id="thanks"
        title="Kiitos"
        paragraph="Viestisi on lähetetty. "
      >
        <Link to="/" smooth={true} duration={500}>
          <Button label="Takaisin" cta="Takaisin" />
        </Link>
      </TextBlock>
    </Layout>
  </>
)
